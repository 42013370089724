<template>
  <div v-if="$can('create','jobs')">
    <b-card>
      <form v-on:submit.prevent="formSubmitted" enctype="multipart/form-data">
      <!-- accoint details tab -->
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                <h5 class="mb-0">
                  Job Details
                </h5>
                <!-- <textarea @paste="handlePaste" rows = "1" placeholder="Paste Data here"></textarea> -->
                <small class="text-muted" v-if="form.jobId">
                  Error : {{ form.jobId }}
                </small>
              </b-col>

              <b-col
                md="4"
                xl="4"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Job Skill *"
                  label-for="jobTitle"
                >
                    <b-form-input
                      id="job-title"
                      v-model="form.jobTitle"
                      class="text-capitalize"
                      type="text"
                      placeholder="FPNA/Python Developer/Java Developer"
                      ref="title"
                    />
                    <small v-if="!form.jobTitle || this.form.jobTitle.includes('_') || this.form.jobTitle.includes(this.form.poc.split(' ')[0])" class="text-danger">{{ error.title }}</small>
                  
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                xl="3"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Position Type *"
                  label-for="position"
                >
                  
                    <b-form-group ref="position">
                      <v-select
                        v-model="form.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="jobTypeOptions"
                        :reduce="(option) => option.title"
                      />
                    </b-form-group>
                  <small v-if="!form.type"  class="text-danger">{{ error.type }}</small>
                  
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                xl="3"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Job Role"
                  label-for="jobRole"
                >
                    <b-form-input
                      id="job-role"
                      v-model="form.role"
                      class="text-capitalize"
                      type="text"
                      placeholder="Job Role"
                      ref="title"
                    />
                    
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Job Band/Job Level"
                  label-for="jobBand"
                >
                    <b-form-input
                      id="job-band"
                      v-model="form.jobBand"
                      class="text-capitalize"
                      type="text"
                      placeholder="Job Band"
                      ref="title"
                    />
                   
                </b-form-group>
              </b-col>
              
              <b-col
                md="3"
                xl="3"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Client *"
                  label-for="client"
                >
                  
                    <b-form-group ref="client">
                      <v-select
                        v-model="form.clientSelected"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="clients"
                        
                      />
                    </b-form-group>
                  <small v-if="!form.clientSelected"  class="text-danger">{{ error.client }}</small>
                  
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                xl="3"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Job Domain *"
                  label-for="jobDomain"
                >
                
                  <b-form-group>
                    <v-select
                      v-model="form.domainSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="domains"
                      ref="domain"
                      style="overflow-x: visible"
                    />
                  </b-form-group>
                  <small v-if="!form.domainSelected" class="text-danger">{{ error.domain }}</small>
                
                </b-form-group>
              </b-col>
              
              <b-col
                md="3"
                xl="3"
                xs="4"
                class="mb-1"
              >
                <b-form-group
                  label="Point Of Contact *"
                  label-for="poc"
                >
                    <b-form-input
                      id="poc"
                      v-model="form.poc"
                      class="text-capitalize"
                      type="text"
                      placeholder="Name of PoC"
                      :state="pocState"
                    />
                    <small v-if="this.error.poc" class="text-danger"> {{ error.poc }}</small>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                xl="3"
                xs="6"
                class="mb-1"
                v-if="form.type === 'Contractual'"
              >
                
                <b-form-group
                  label="Payroll *"
                  label-for="payroll"
                  
                >
                    <v-select
                      v-model="form.payroll"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="payrollOptions"
                      ref="payroll"
                      style="overflow-x: visible"
                      :reduce="(option) => option.title"
                    />
                    <small v-if="this.error.payroll" class="text-danger"> {{ error.payroll }}</small>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                xl="3"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Category *"
                  label-for="category"
                  
                >
                    <v-select
                      v-model="form.category"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="categoryOptions"
                      ref="category"
                      style="overflow-x: visible"
                      :reduce="(option) => option.title"
                    />
                    <small v-if="this.error.category" class="text-danger"> {{ error.category }}</small>
                </b-form-group>
              </b-col>
              
              <b-col
                md="3"
                xl="3"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Vertical *"
                  label-for="vertical"
                  
                >
                    <v-select
                      v-model="form.vertical"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="verticalOptions"
                      ref="vertical"
                      style="overflow-x: visible"
                      :reduce="(option) => option.title"
                    />
                    <small v-if="this.error.vertical" class="text-danger"> {{ error.vertical }}</small>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                xl="3"
                xs="6"
                class="mb-1"
              >
              <b-form-group
                  label="Target Date *"
                  label-for="targetDate"
                  
                >
                    <b-form-datepicker
                      id="targetDate"
                      v-model="form.targetDate"
                      class="mb-1"
                    />
                  
                </b-form-group>
              </b-col>  
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Notice Period *"
                  label-for="noticePeriod"
                >
                  <v-select
                      id="notice-period"
                      v-model="form.noticePeriod"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="noticePeriod"
                      ref="np"
                    />
                    <!--<b-form-input
                      id="notice-period"
                      v-model="form.noticePeriod"
                      type="number"
                      ref="np"
                      placeholder="Number of Days"
                    />-->
                    <small v-if="!form.noticePeriod" class="text-danger">{{ error.noticePeriod }}</small>
                 
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="No of Positions *"
                  label-for="noOfPostion"
                >
                  
                    <b-form-input
                      id="noOfPositions"
                      v-model="form.noOfPositions"
                      type="number"
                      ref="nop"
                      placeholder="Number of Vacancies"
                    />
                    <small v-if="!form.noOfPositions" class="text-danger">{{ error.noOfPosition }}</small>
                 
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Min Experience *"
                  label-for="min-exp"
                >
                    <b-form-input
                      id="min-exp"
                      v-model="form.minExp"
                      type="number"
                      step=".01"
                      placeholder="Exp in years"
                      ref="minexp"
                    />
                    <small v-if="!form.minExp" class="text-danger">{{ error.minExp }}</small>
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Max Experience *"
                  label-for="max-exp"
                >                
                    <b-form-input
                      id="max-exp"
                      v-model="form.maxExp"
                      type="number"
                      step=".01"
                      ref="maxexp"
                      placeholder="Exp in years"
                    />
                    <small v-if="!form.maxExp" class="text-danger">{{ error.maxExp }}</small>
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Minimum CTC *"
                  label-for="minCTC"
                >
                    <b-form-input
                      id="min-ctc"
                      v-model="form.minCTC"
                      type="number"
                      step=".01"
                      ref="minctc"
                      :placeholder="form.type === 'Contractual' && form.vertical === 'Infra Industry' && form.category === 'Blue Collar' && form.payroll === 'Sattva Payroll' ? 'Monthly salary': 'Enter Amount in lacs'"
                    />
                    <small v-if="!form.minCTC" class="text-danger">{{ error.minCTC }}</small>
                 
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                xl="2"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Maximum CTC *"
                  label-for="minCTC"
                >
                  
                    <b-form-input
                      id="max-ctc"
                      v-model="form.maxCTC"
                      type="number"
                      step=".01"
                      ref="maxctc"
                      :placeholder="form.type === 'Contractual' && form.vertical === 'Infra Industry' && form.category === 'Blue Collar' && form.payroll === 'Sattva Payroll' ? 'Monthly salary': 'Enter Amount in lacs'"
                    />
                    <small v-if="!form.maxCTC" class="text-danger">{{ error.maxCTC }}</small>
                 
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                xl="3"
                xs="6"
                class="mb-1"
              >
                <b-form-group
                  label="Offered Position"
                  label-for="offeredPosition"
                >
                  
                    <b-form-input
                      id="offeredPosition"
                      v-model="form.offeredPosition"
                      type="text"
                      placeholder="Offered Position Name"
                    />
                    <!-- <small v-if="!form.noOfPositions" class="text-danger">{{ error.noOfPosition }}</small> -->
                 
                </b-form-group>
              </b-col>
              
              <b-col
                md="6"
                xl="6"
                xs="6"
                class="mb-1"
              >
              <ValidationProvider name="Desired Skills" rules="required|desiredSkills" v-slot="{ errors }">
                <b-form-group
                  label="Desired/Mandatory Skills *"
                  label-for="desiredSkills"
                >
                  <b-form-input
                    v-model="form.skills"
                    placeholder="Enter Skills Comma Separated"
                    type="text"
                    ref="skill"
                  />
                  <span class="text-danger">{{ errors[0] }}</span>
                  <small v-if="!form.skills" class="text-danger">{{ error.skills }}</small>
                
                </b-form-group>
              </ValidationProvider>
              </b-col>
              
              
            </b-row>
          
        
          <div>
            
            <b-row>
              <b-col
                md="6"
                xl="6"
                xs="6"
                class="mb-1"
              >
              <h5 class="mb-1 mt-1">
              Account Manager
            </h5>
                <b-form-group
                  label="Account Mgr/Team Lead *"
                  label-for="accountMgr"
                >
                  <v-select
                      v-model="form.mgrSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="accountMgr"
                      ref="accMgr"
                    />
                  <small v-if="!form.mgrSelected" class="text-danger">{{ error.accMgr }}</small>
                </b-form-group>
              </b-col>
              <b-col
                md="6"
                xl="6"
                xs="6"
                class="mb-1"
              >
              <h5 class="mb-1 mt-1">
              Sourcer
            </h5>
                <b-form-group
                  label="Sourcer *"
                  label-for="sourcer"
                >
                  <v-select
                      v-model="form.sourcerSelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      multiple
                      :options="recruiters"
                      :close-on-select="false"
                      ref="sourcer"
                    />
                  <small v-if="!form.sourcerSelected" class="text-danger">{{ error.sourcer }}</small>
                </b-form-group>
              </b-col>
            </b-row>
          </div>  
          <div>
            <h5 class="mb-1 mt-1">
              Location & Recruiters
            </h5>
            <b-row>
            <b-col
              md="6"
              xl="6"
              xs="6"
              class="mb-2"
            >
              <b-form-group
                label="City *"
                label-for="city"
              >
                  
                  <b-form-group>
                    <v-select
                      v-model="form.citySelected"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :close-on-select="false"
                      multiple
                      :options="cities"
                      ref="city"
                    />
                  </b-form-group>
                  <small v-if="!form.citySelected" class="text-danger">{{ error.city }}</small>
               
              </b-form-group>
            </b-col>  
            <b-col
              md="6"
              xl="6"
              xs="6"
              class="mb-1"
            > 
            
              <b-form-group
                label="Recruiters *"
                label-for="recruiters"
              >
                <v-select
                  v-model="form.recruitersSelected"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  :close-on-select="false"
                  label="title"
                  :options="recruiters"
                  ref="recruit"
                />
              <small v-if="!form.recruitersSelected" class="text-danger">{{ error.recruiter }}</small>
              
              </b-form-group>
              
              
            </b-col>
          </b-row>
          </div>
          <div>
            <h5 class="mb-1 mt-1">
              Job Description *
            </h5>
            <b-row>
              <b-col
                cols="12"
                class="mb-2"
              >
                
                <!--<small class="text-muted">Enter Job Description</small>-->
              </b-col>
              <b-col
                cols="12"
                class="mb-2"
              >
                <quill-editor
                  v-model="form.jdContent"
                  :options="snowOption"
                  ref="jd"

                />
                <small class="text-danger">{{ error.jd }}</small>
              </b-col>
              <b-col
                cols="6"
                xs="12"
                class="mb-2"
              >
                <label for="textarea-default">Recruiter Notes *</label>
                <b-form-textarea
                  id="textarea-default"
                  v-model="form.notes"
                  placeholder="Add Notes"
                  rows="3"
                />
                <small class="text-danger">{{ error.notes }}</small>
              </b-col>
              <b-col
                cols="6"
                xs="12"
                class="mb-2 mt-3"
              >
                <div>
                  
                  <!-- <div style="float:left">
                    <b-card-text class="mb-1">
                      Is JD Available?
                    </b-card-text>
                    <b-form-checkbox v-model="checked" name="check-button" switch>
                      
                    </b-form-checkbox>
                  </div> -->
                </div>
                <b-form-file 
                  name="jdFile"
                  placeholder="ADD Job Description file here... *"
                  drop-placeholder="Drop Job Description file here..."
                  @change='openFile'
                  type="file"
                  v-model="form.file"
                  accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, text/plain, application/rtf, text/markdown, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                />
                <!-- <b-form-file v-if="checked"
                  name="jdFile"
                  placeholder="ADD Job Description file here..."
                  drop-placeholder="Drop Job Description file here..."
                  @change='openFile'
                  type="file"
                  v-model="form.file"
                  accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, text/plain, application/rtf, text/markdown, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation"
                /> -->
                <small class="text-danger">{{ error.file }}</small>
                <small v-if="form.file && form.file.size > 1600000" class="text-danger"> File Size Should be less than 1.5 mb </small>
              </b-col>

            </b-row>
          </div>
          
          <div>
            <b-row>
              <b-col
              md="12"
              xl="12"
              xs="12"
              class="mb-2"
              >
              <b-button variant="relief-success" value="submit" id="submit" type="submit" width="100%">Submit</b-button>
              
              </b-col>
            </b-row>
          </div>

      </form>
    </b-card>
  </div>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
extend('required', required);
extend('desiredSkills', {
  validate(value) {
    const pattern = /^(\b\w+\b[\s,]*){1,4}$/;
    return value.split(',').every((skill) => pattern.test(skill.trim()));
  },
  message: 'Each skill must consist of 1 to 4 words, separated by commas.'
});
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { quillEditor } from 'vue-quill-editor'
import moment from 'moment'
import JobService from '../../../services/job-service'
import NoteService from '../../../services/note-service'
import UserService from '@/services/user-service'
import jobTimelineService from '@/services/job-timeline'

import firebaseMessaging from './../../../firebase'
import clientService from '@/services/client-service'
import domainService from '@/services/domain-service'
import adminConfigService from '@/services/admin-config-service'
//import { BFormCheckbox } from 'bootstrap-vue'

import {
  BCard,
  BRow,
  BCol,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormDatepicker,
  BFormFile,
  BFormTextarea,
  BFormCheckbox
} from 'bootstrap-vue'
import locationService from '@/services/location-service'
//import { required, email } from '@validations'
// import { codeIcon } from './code'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BCard,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BFormDatepicker,
    BFormCheckbox,
    vSelect,
    quillEditor,
    BFormFile,
    BFormTextarea,
    BFormInvalidFeedback,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      form:{
        jobId: '',
        id: '',
        jobTitle: '',
        band: '',
        role: '',
        clientSelected: '',
        domainSelected: '',
        poc: '',
        targetDate: '',
        minExp: '',
        maxExp: '',
        minCTC: '',
        maxCTC: '',
        noticePeriod: '',
        noOfPositions: '',
        skillsSelected: '',
        citySelected: '',
        recruitersSelected: '',
        jdContent: '',
        notes: '',
        file: '',
        mgrSelected: '',
        sourcerSelected: '',
        type: '',
        category: '',
        vertical: ''
      },
      promiseFulfilled: false,
      promiseRejected: false,
      snowOption: {
        theme: 'snow',
      },
      error: {
        title: '',
        client: '',
        domain: '',
        minExp: '',
        maxExp: '',
        minCTC: '',
        maxCTC: '',
        noticePeriod: '',
        noOfPositions: '',
        skills: '',
        city: '',
        recruiter: '',
        sourcer: '',
        jd: '',
        accMgr: '',
        notes: '',
        type: '',
        payroll: '',
        category: '',
        vertical: ''

      },
      clients: [],
      domains:[],
      recruiters: [],
      accountMgr: [],
      sourcer: [],
      cities: [],
      fcmToken: '',
      noticePeriod: [{title: "Immediate", value: 0}, {title: "0-15", value: 15}, {title: "0-30", value: 30},{title: "0-45", value: 45},{title: "0-60", value: 60}, {title: "0-90", value: 90}],
      checked: true,
      jobTypeOptions: [{title: "Full Time"}, {title: "Contractual"}, {title: "Fixed Term"}, {title: "Cost Plus"}],
      payrollOptions: [{title: "Client Payroll"}, {title: "Sattva Payroll"}, {title: "Third Party"}],
      categoryOptions: [{title: "White Collar"}, {title: "Blue Collar"}],
      verticalOptions: [{title: "Service Industry"}, {title: "Infra Industry"}]
      //type: null
    }
  },
  mounted() {
    adminConfigService.getJobsDropdownList().then(res=>{
        this.jobStatusOptions = res.data.data.jobStatusList
        this.jobTypeOptions = res.data.data.jobTypeList
        this.categoryOptions = res.data.data.categoryList
        this.payrollOptions = res.data.data.payrollList
        this.verticalOptions = res.data.data.verticalList
    })
  },
  computed: {
    pocState() {
        let regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
         return this.error.poc = this.form.poc && !regName.test(this.form.poc) ? 'Please Enter Full Name' : ''
    }
  },
  created() {
    var checkbox = document.querySelector("#__BVID__171")
    //document.querySelector("#__BVID__171")
    console.log('checked',this.checked, checkbox)

    UserService.getAllRecruitersList().then(res =>{
        console.log('recruiters ',res.data)
        this.recruiters = res.data.filter(item => item.status === 'ACTIVE')
    })
    UserService.getAllManagersList().then(res =>{
      console.log('managers ',res.data)
      this.accountMgr = res.data.filter(item => item.status === 'ACTIVE')
    })
    // UserService.getAllUsersDd().then( res => {
    //   if(res.status === "OK") {
    //     console.log("in if", res.data)
    //     this.recruiters = res.data.filter(item => item.role === "talent-advisor-sourcer" || item.role === "account-manager" || item.role === "talent-advisor");
    //     // this.accountMgr = res.data.filter(item => item.role !== "talent-advisor-sourcer" && item.role !== "talent-advisor" && item.role !== "mis" && item.role !== "supervisor" && item.role !== "accountant" && item.role !== "hr" && item.status === 'ACTIVE');
    //     this.accountMgr = res.data.filter(item => item.role === 'account-manager' || item.role === 'program-manager' || item.role === 'master' || item.role === 'hr-head')
    //     console.log('Account manager: ', this.accountMgr)
    //     // const am = {
    //     //     "id": 91,
    //     //     "title": "Tarun Malhotra",
    //     //     "emp_id": "SH-094",
    //     //     "role": "master",
    //     //     "email": "tarun.malhotra@sattvahuman.com",
    //     //     "manager": "SH-003"
    //     // }
    //     // const am1 = {
    //     //     "id": 103,
    //     //     "title": "Samantha Victor",
    //     //     "emp_id": "SH-105",
    //     //     "role": "master",
    //     //     "email": "samantha.victor@sattvahuman.com",
    //     //     "manager": "SH-003"
    //     // }
    //     // const am2 = {
    //     //     "id": 25,
    //     //     "title": "Dhanya",
    //     //     "emp_id": "SH-007",
    //     //     "role": "master",
    //     //     "email": "dhanya@sattvahuman.com",
    //     //     "manager": "SH-003"
    //     // }
    //     // const am3 = {
    //     //     "id": 3,
    //     //     "title": "Yogesh Tiwari",
    //     //     "emp_id": "SH-003",
    //     //     "role": "master",
    //     //     "email": "yogesh@sattvahuman.com",
    //     //     "manager": "SH-003"
    //     // }
    //     // this.accountMgr.push(am);
    //     // this.accountMgr.push(am1);
    //     // this.accountMgr.push(am2);
    //     // this.accountMgr.push(am3);
    //     this.sourcer = res.data.filter(item => item.role === "talent-advisor-sourcer" || item.role === "account-manager");

    //     const srcr = {
    //         "id": 3,
    //         "title": "Yogesh Tiwari",
    //         "emp_id": "SH-003",
    //         "role": "master",
    //         "email": "yogesh@sattvahuman.com",
    //         "manager": "SH-003"
    //     }
    //     const srcr1 = {
    //         "id": 91,
    //         "title": "Tarun Malhotra",
    //         "emp_id": "SH-094",
    //         "role": "master",
    //         "email": "tarun.malhotra@sattvahuman.com",
    //         "manager": "SH-003"
    //     }
    //     this.sourcer.push(srcr);
    //     this.sourcer.push(srcr1);
    //     this.recruiters.push(srcr);
    //     this.recruiters.push(srcr1);
    //     console.log(this.recruiters)
    //     console.log(this.accountMgr)
    //     console.log(this.sourcer)
    //   }
      
    // }).then( d => {
    // console.log(this.recruiters.length, !this.recruiters.length)
    
    // })
    clientService.getClients().then(res => {
      this.clients = res.data
      console.log(this.clients)
    })
    domainService.getDomains().then(res => {
      this.domains = res.data
      console.log(this.domains)
    })
    locationService.getAll().then(res => {
      console.log(res.data)
      this.cities = res.data
    })

    var today = new Date();
    this.form.targetDate = moment().locale("en").add(30, 'days').format("YYYY-MM-DD")
    
    
  },
  destroyed() {
    //window.removeEventListener('resize', this.initTrHeight)
    //
  },
  methods: {
    formSubmitted() {
      if(!this.$can('create','jobs')) {
        return
      }
      const validation = this.validationForm();
      console.log(validation)
      console.log('JD Checked', this.checked)
      console.log('form', this.form)
      
      if(validation) {
        const formData = new FormData()
        
        //console.log(this.form.recruitersSelected, this.form.mgrSelected)
        const cities = JSON.parse(JSON.stringify(this.form.citySelected)).map(({ title }) => title).join(',');
        const rid = JSON.parse(JSON.stringify(this.form.recruitersSelected)).map(({ id }) => id);
        const sid = JSON.parse(JSON.stringify(this.form.sourcerSelected)).map(({ id }) => id);
        const aid = [this.form.mgrSelected.id];
        console.log("accmgr",aid.id, rid, sid )
        let all_id = [...rid, ...aid, ...sid]
        let uniq_id = [...new Set(all_id)];
        console.log(all_id, uniq_id)

        
        const userData = JSON.parse(localStorage.getItem('userData'));

        const user = `[{"title" : "${userData.first_name} ${userData.last_name}", "emp_id": "${userData.emp_id}"}]`
        const username = `${userData.first_name} ${userData.last_name}`
        console.log("notice period", this.form.noticePeriod)
        console.log("notice period", typeof(this.form.noticePeriod))
        const recruiters = JSON.stringify(this.form.recruitersSelected)
        const sourcer = JSON.stringify(this.form.sourcerSelected)
        const mgr = '[' + JSON.stringify(this.form.mgrSelected) + ']'
        const client = '[' + JSON.stringify(this.form.clientSelected) + ']'
        const domain = '[' + JSON.stringify(this.form.domainSelected) + ']'
        formData.append('position_name', this.form.jobTitle)
        formData.append('role', this.form.role)
        formData.append('band', this.form.jobBand)
        formData.append('client', client)
        formData.append('domain', domain)
        formData.append('no_of_vacancies', this.form.noOfPositions)
        formData.append('locations', cities)
        formData.append('target_date', this.form.targetDate)
        formData.append('min_ctc', this.form.minCTC)
        formData.append('max_ctc', this.form.maxCTC)
        formData.append('min_exp', this.form.minExp)
        formData.append('max_exp', this.form.maxExp)
        formData.append('notice_period', this.form.noticePeriod.value)
        
        formData.append('desired_skills', this.form.skills)
        //formData.append('mandatory_skills', this.form.skills)
        formData.append('job_description', this.form.jdContent)
        formData.append('poc', this.form.poc)
        formData.append('recruiters', recruiters)
        formData.append('account_mgr', mgr)
        formData.append('sourcer',sourcer)
        //formData.append('attachments', this.form.jobTitle)
        formData.append('status', 'Active')
        formData.append('created_by', user)
        formData.append('updated_by', user)
        //formData.append('notes', this.form.notes)
        formData.append('type', this.form.type)
        formData.append('payroll', this.form.payroll ? this.form.payroll : this.form.type === 'Full Time' || this.form.type === 'Fixed Term' ? 'Client Payroll' : '')
        formData.append('category', this.form.category)
        formData.append('vertical', this.form.vertical)
        if(this.checked) {
          formData.append('jdFile', this.form.file)
        }
        const accessToken = localStorage.getItem('accessToken')
        console.log("jobtitle", this.form.jobTitle, this.form.poc, this.form.jobTitle.includes(this.form.poc.split(' ')[0]) )

        JobService.addJob(formData).then( res => {
          console.log("addjob", res)
          
          if(res.status === "OK") {

          
          const job_id = res.data.job_id
          const clientName = this.form.clientSelected.title
          const jobTitle = res.data.position_name;
          const noOfPositions = res.data.no_of_vacancies;
          this.id = res.data.id;
          //console.log("jobid", job_id)
          const notesData = {
            note_description: this.form.notes,
            jobId: this.id,
            recruiter_id: user
          }
          console.log(notesData)
            NoteService.addNote(notesData).then(res => {
              console.log("note", res)

              if (res.status === "OK") {
                const timelineData = {
                  title: `${job_id} - New Job Added - ${clientName} - ${jobTitle}`,
                  description: `${noOfPositions} open positions`,
                  type: "info",
                  createdBy: username,
                  jobId: this.id,
                  candidate_id: null
                }
                console.log(timelineData);
                /*const ids = JSON.stringify({
                  "id" : all_id
                })*/
                console.log(all_id)
                jobTimelineService.create(timelineData).then(res => {
                  console.log("timeline", res)
                  if(res.status === "OK") {
                    UserService.getToken(uniq_id).then(res => {
                      console.log(res.data)
                      this.tokenData = res.data
                      
                      for (let i=0; i < this.tokenData.length; i++) {
                        let BASE_URL = ''
                        if (process.env.NODE_ENV === "production") {
                            BASE_URL = 'https://ats.sattvahuman.in/';
                        } else {
                            BASE_URL = 'http://localhost:8080/';
                        }
                        const payload = {
                              "notification": {
                                "title": `New Job assigned - ${clientName} - ${jobTitle}`,
                                "body": `${noOfPositions} open positions, Click here for more details`,
                                // /*dev*/"click_action": `http://localhost:8080/#/apps/view-job/${job_id}`,
                                "click_action": `${BASE_URL}/#/apps/view-job/${job_id}`,
                                // /*prod*/ // "click_action": `https://ats.sattvahuman.in/#/apps/view-job/${job_id}`,
                                "icon": "https://ats.sattvahuman.in/img/logo.77e558a1.svg"
                              },
                              "to": this.tokenData[i].fcm_token
                          }
                          UserService.pushNotification(payload).then(res => {
                            console.log(res)
                            if(process.env.NODE_ENV === "production") {
                              firebaseMessaging.onMessage((payload) => {
                                console.log('Message received. ', payload);
                              });
                            }  
                        })
                      }
                    })
                  } else {
                    this.form.jobId = res
                  }
                })
                //const id = JSON.parse(localStorage.getItem('userData')).id

                
                this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'Job Added',
                        icon: 'EditIcon',
                        variant: 'success',
                      },
                })
                 this.$router.push({ name: 'apps-jobs'})
              } else {
                this.form.jobId = res
              }
            })
          } else if ((res.status === 401 ) || (res.status === 403 )) {
            localStorage.clear();
            this.$router.push({ name: "auth-login" });
          } else if(res.status === 'FAILED') {
            this.form.jobId = res.data.message
          }
        })

      }
      
      
    },
    openFile (event) {
        this.form.file = event.target.files[0];
        console.log("file", this.form.file.size)
        
    },
    validationForm() {
      if (!this.form.jobTitle || this.form.jobTitle.includes('_') || this.form.jobTitle.includes(this.form.poc.split(' ')[0])) {
        
        if(!this.form.jobTitle) {
          this.error.title="Title is Mandatory"
        } else if (this.form.jobTitle.includes('_')) {
          this.error.title="Please do not use underscore in job title"
        } else if (this.form.jobTitle.includes(this.form.poc.split(' ')[0])) {
          this.error.title="Please do not enter Client Name in job title"
        }
        this.$nextTick(() => {
          this.$refs.title.focus()
        })
        return false
      }
      if(!this.form.type) {
        this.error.type="Position Type is Mandatory"
        return false
      }
      if (!this.form.clientSelected) {
        this.error.client="Client is Mandatory"
        
        return false
      }
      if (!this.form.domainSelected) {
        this.error.domain="Domain is Mandatory"
        
        return false
      }
      if (!this.form.category ) {
        this.error.category="Category is Mandatory!"
        return false
      }
      if (!this.form.vertical ) {
        this.error.vertical="Vertical is Mandatory!"
        return false
      }
      if (!this.form.payroll && this.form.type === 'Contractual') {
        this.error.payroll="Payroll is Mandatory for Contractual Jobs!"
        return false
      }
      let regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
      if(this.form.poc && !regName.test(this.form.poc))
      {
        return false
      }
      if (!this.form.minExp) {
        this.error.minExp="Min Exp is Mandatory"
        this.$nextTick(() => {
          this.$refs.minexp.focus()
        })
        return false
      }
      if (!this.form.maxExp) {
        this.error.maxExp="Max Exp is Mandatory"
        this.$nextTick(() => {
          this.$refs.maxexp.focus()
        })
        return false
      }
      if (!this.form.minCTC) {
        this.error.minCTC="Min CTC is Mandatory"
        this.$nextTick(() => {
          this.$refs.minctc.focus()
        })
        return false
      }
      if (!this.form.maxCTC) {
        this.error.maxCTC="Max CTC is Mandatory"
        this.$nextTick(() => {
          this.$refs.maxctc.focus()
        })
        return false
      }
      if (!this.form.noticePeriod) {
        this.error.noticePeriod="Notice Period is Mandatory"
        this.$nextTick(() => {
          this.$refs.np.focus()
        })
        return false
      }
      if (!this.form.noOfPositions) {
        this.error.noOfPositions="No Of Positions is Mandatory"
        this.$nextTick(() => {
          this.$refs.nop.focus()
        })
        return false
      }
      if (!this.form.skills || !this.validateDesiredSkills) {
        this.error.skills="Skills is Mandatory or Skill Validation Failed"
        return false
      }
      if (!this.form.citySelected) {
        this.error.city="City is Mandatory"
        
        return false
      }
      if (!this.form.recruitersSelected) {
        this.error.recruiter="Recruiter is Mandatory"
        
        return false
      }
      if (!this.form.sourcerSelected) {
        this.error.sourcer="Sourcer is Mandatory"
        
        return false
      }
      if (!this.form.jdContent && length(this.form.jdContent) < 120) {
        this.error.jd="JD is Mandatory/ Content added is not enough, please add in detail"
        return false
      }
      if (!this.form.mgrSelected) {
        this.error.accMgr="Account Manager/TL is Mandatory"
        
        return false
      }
      if (!this.form.notes) {
        this.error.notes="Note is mandatory"
        
        return false
      }
      if(this.checked) {
        if(!this.form.file || this.form.file.size > 2010000 ) {
          if(this.form.file.size > 201000) {
            this.error.file="File should not be greater than 2MB"
          } else {
            this.error.file="JD File is mandatory"
          }
          return false
        }
      }
      return true
    },
    // validateDesiredSkills() {
    //   console.log('in desired skill validation')
    //   // Regular expression to match the pattern
    //   const pattern = /^(\w+(\s\w+){0,3},\s?)*(\w+(\s\w+){0,3})?$/;
    //   console.log('in desired skill validation', pattern.test(this.form.skills))
    //   return pattern.test(this.form.skills);
    // },
    validateDesiredSkills() {
      this.error.skills = null
      console.log('in desired skill validation', this.form.skills)
      const regex = /^(?:\s*\w+\s*,){2,}\s*\w+\s*$/;
      if(!regex.test(this.form.skills)) {
        this.error.skills = 'Alteast 3 skills required separated by comma'
      }
      return regex.test(this.form.skills);
    },
    // handlePaste(e) {
    //   console.log('handle paste', e)
    //   // if(this.candidateData.skillsSelected === '' || this.candidateData.skillsSelected) {
    //   //   this.candidateData.skillsSelected = this.jobData.position_name
    //   //   console.log('in updated skill selected', this.candidateData.skillsSelected, this.jobData.position_name);
    //   // } 
    //   const clipboardData = e.clipboardData || window.clipboardData;
    //   const pastedData = clipboardData.getData('Text');
    //   console.log('paste data: ', clipboardData, pastedData)
    //   try {
    //     const jsonData = JSON.parse(pastedData);
        
    //     for (const key in jsonData) {
    //       if (this.form.hasOwnProperty(key)) {
    //         this.form[key] = jsonData[key];
    //       }
    //     }
    //     // // this.candidateData.selectedCity = jsonData.selectedCity
    //     // // this.candidateData.qualification = jsonData.qualification
    //     // this.candidateData.lastName = jsonData.LastName ? jsonData.LastName : '.'
    //     // console.log(jsonData.dob)
    //     // this.candidateData.dob = jsonData.dob ? this.formatDate(jsonData.dob) : null
    //     e.clipboardData = null
    //     window.clipboardData = null
    //     setTimeout( () => {
    //       console.log('running checck in 100 millisecond to check if candidate exist')
    //       this.runCheck()
    //     },100)
    //   } catch (error) {
    //     console.error('Failed to parse JSON data:', error);
    //     // Handle error for invalid JSON data
    //   }
    // },
  },

}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/quill.scss';
  .repeater-form {
  overflow: hidden;
  transition: .35s height;
}

</style>
